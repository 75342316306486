<template>
  <div>
    <p style="color: red">*Em caso de ASO AVULSA preencher o CNPJ com ZEROS na frente do CPF.</p>
    <b-row>
      <b-col>
        <p align="left">
          <b-form-group label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
            <b-input-group size="sm">
                 <b-form-input name="filter-input_cnpj" id="filter-input_cnpj" v-model="filter2"   v-on:keyup="searchEmpresaCnpj"  type="search" placeholder="Pesquise aqui CNPJ da Empresa"></b-form-input>
                   <b-input-group-append>
                        <b-button :disabled="!filter2" @click="filter2 = ''">Limpar</b-button>
                   </b-input-group-append>
                      &nbsp;   &nbsp;   &nbsp;
                    <b-button @click="createItem" pill variant="success" size="sm">Novo Registro</b-button>
                            &nbsp;   &nbsp;
                    <b-button  type="button" scale="2" variant="outline-success"  v-on:click="download" ><b> Excel</b> <b-icon icon="file-excel" ></b-icon></b-button>
              </b-input-group>
<br>
               <b-input-group size="sm">
                <b-form-input name="filter-input" id="filter-input" v-model="filter"  v-on:keyup="searchEmpresa"  type="search" placeholder="Pesquise aqui Nome da Empresa"></b-form-input>
                     <b-input-group-append>
                          <b-button :disabled="!filter" @click="filter = ''">Limpar</b-button>
                     </b-input-group-append>
                            <b-input-group-append>&nbsp;   &nbsp;   &nbsp;<a href="https://solucoes.receita.fazenda.gov.br/Servicos/cnpjreva/Cnpjreva_Solicitacao.asp" target="_blank"> Receita Federal</a></b-input-group-append>
                </b-input-group>
            </b-form-group>
        </p>
      </b-col>

      <b-col>
           &nbsp;
      </b-col>

      <b-col>
           &nbsp;
      </b-col>

    </b-row>

    <b-table striped hover responsive id="my-table" :busy="isBusy" :items="listEmpresas"  :filter="filter" 
      :fields="visibleFields" :per-page="perPage" :current-page="currentPage" aria-controls="my-table" small>

      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(opcao)="data">

        <router-link
          class="home-link"
          :to="{ name: 'convenioempresa', query:{empresa_id: data.item.id } }"
          :hidden="habilitaConvenio(data.item.convenio)"
        >
          <b-icon icon="credit-card" cursor="pointer"  title="Convênio Empresa" variant="success" font-scale="1.2"></b-icon>

        </router-link>
        &nbsp;
        <b-icon icon="pencil-fill" title="Editar Empresa" cursor="pointer" @click="editItem(data.item)" pill variant="primary" size="sm">
        </b-icon>
        &nbsp;
        <b-icon icon="trash"   :hidden="iconVisible(user.perfil)" title="Excluir Empresa" cursor="pointer" @click="deleteItem(data.item)" pill v-b-modal="'edit-modal'"
          variant="danger" size="sm">
        </b-icon>
      </template>

    </b-table>

    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>

    <!-- Info modal -->
    <b-modal v-model="modalShow" size="lg"  hide-footer>
      <b-form @submit.prevent="save">

        <b-form-group id="input-group-2" label="Nome:" label-for="input-2">
          <b-form-input id="input-2" hidden="true" v-model="formdata.id" placeholder="id"></b-form-input>
          <b-form-input id="input-2" v-model="formdata.nome" required placeholder="Nome"></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Cnpj:" label-for="input-2">
          <b-form-input id="input-2" v-mask="'##.###.###/####-##'" v-model="formdata.cnpj" required placeholder="Cnpj">
          </b-form-input>
        </b-form-group>


        <b-form-group id="input-group-2" label="Cpf:" label-for="input-2">
          <b-form-input id="input-2" v-mask="'###.###.###-##'" v-model="formdata.cpf" placeholder="Cpf">
          </b-form-input>
        </b-form-group>


        <b-form-group id="input-group-2" label="Inscrição E-Social:" label-for="input-2">
          <b-form-input id="input-2" v-model="formdata.ideEmpregador" placeholder="Insc. E-Social">
          </b-form-input>
        </b-form-group>



        <b-form-group id="input-group-2" label="Telefone:" label-for="input-2">
          <b-form-input id="input-2" v-mask="'(##) #####-####'" v-model="formdata.telefone" required
            placeholder="Telefone"></b-form-input>
        </b-form-group>


        <b-form-group id="input-group-2" label="Inscrição Estadual:" label-for="input-2">
          <b-form-input id="input-2" v-model="formdata.inscricaoestadual"  placeholder="Insc. Estadual">
          </b-form-input>
        </b-form-group>


        <b-form-group id="input-group-2" label="Inscrição Municipal:" label-for="input-2">
          <b-form-input id="input-2" v-model="formdata.inscricaomunicipal"  placeholder="Insc. Municipal">
          </b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Endereço:" label-for="input-2">
          <b-form-input id="input-2" v-model="formdata.endereco"  placeholder="Endereço"></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Convenio:" label-for="checkbox-1" size="sm" switch>
          <b-form-checkbox class="mb-3" id="checkbox-1" v-model="formdata.convenio" required placeholder="Convenio"></b-form-checkbox>
        </b-form-group>

        <b-form-group id="input-group-2" label="E-Social:" label-for="checkbox-2" size="sm" switch>
          <b-form-checkbox class="mb-3" id="checkbox-2" v-model="formdata.esocial"  placeholder="E-Social"></b-form-checkbox>
        </b-form-group>

      
          <b-form-group class="mb-3" label="Empresa de Fora:*" >
                  <b-form-select  id="empresafora" name="empresafora" v-model="formdata.empresafora" >
                      <template #first>
                         <b-form-select-option :value="null" disabled>Selecione abaixo</b-form-select-option>
                      </template>
                      <b-form-select-option
                                      v-for="(item, index) in empresasfora"
                                      v-bind:key="index.id"
                                      v-bind:value="item.id"
                                    >
                                    {{ item.nome }} 
                      </b-form-select-option>
                  </b-form-select>
           </b-form-group>




        <b-form-group id="input-group-2" label="Responsavel:" label-for="input-2">
          <b-form-input id="input-2" v-model="formdata.responsavel"  placeholder="Responsavel"></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Email:" label-for="input-2">
          <b-form-input id="input-2" v-model="formdata.email"  placeholder="Email"></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" label="Observação:" label-for="input-2">
            <b-form-input id="input-2" v-model="formdata.observacao" placeholder="Observacao"></b-form-input>
        </b-form-group>

        <br />
        <b-button size="sm" pill variant="danger" @click="close">
          Cancelar
        </b-button>
        &nbsp;
        <b-button type="submit" pill size="sm" variant="success">
          Salvar
        </b-button>
      </b-form>
    </b-modal>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { http } from '@/plugins/axios'
import * as XLSX from 'xlsx'
export default {

        data () {
          return {
              isBusy: false,
              perPage: 20,
              currentPage: 1,
              filter: '',
              filter2: '',
              filterinput: null,
              infoModal: {
                        id: 'info-modal',
                        title: '',
                        content: ''
                        },
              fields:[  
                      {
                        key: 'id',
                        label:'id',
                        visible: false,
                        sortable: true,
                        thStyle: { width: "2%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'nome',
                        label:'Nome Empresa',
                        formatter: 'todasMaiusculas',
                        sortable: true, 
                        visible: true,
                        thStyle: { width: "30%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'cnpj',
                        label:'Cnpj',
                        formatter: 'mascaraCnpj',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "15%"},
                        tdClass: 'fonteLinhasLeft'
                      },            
                      {
                        key: 'telefone',
                        label:'Telefone',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "15%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                        {
                        key: 'endereco',
                        label:'Endereço',
                        sortable: true,
                        visible: false,
                        thStyle: { width: "20%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                        {
                        key: 'responsavel',
                        label:'Responsavel',
                        formatter: 'todasMaiusculas',
                        sortable: true,
                        visible: false,
                        thStyle: { width: "15%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'email',
                        label:'Email',
                        sortable: true,
                        visible: false,
                        thStyle: { width: "15%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'esocial',
                        label:'E-Social',
                        formatter: 'trueParaSim',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "15%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                       {
                        key: 'convenio',
                        label:'Convenio',
                        formatter: 'trueParaSim',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "15%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'opcao',
                        visible: true,
                        thStyle: { width: "15%" },
                        label:'Opções',
                        sortable: true
                      },
                                    
          ],
             listEmpresas: [],
             formdata:{},
             empresasfora:[{
              "id":"sim",
              "nome":"sim"
             }, 
             {
                "id": "nao",
                "nome": "não"
              },],
             modalShow: false,
             editedIndex: -1
          }
        },
       
       mounted(){
            
         this.ActionFindEmpresa(),
             setTimeout(() =>{// aguarda com spinner antes da pesquisa aparecer na pesquisa inicial
                  this.isBusy = false
                  }, 2.0*2000)
        },

        computed:{
         
          ...mapState('auth', ['user']),

          isDisabled() {
            var pesquisa = new String(this.filterinput)

              if (pesquisa.length > 5){
                  return true
                    }
                else {
                  return false
                }
              },

           visibleFields() {
                    return this.fields.filter(field => field.visible)
                           },
           rows() {
                    return this.listEmpresas.length
                  },
                 // ...mapState('empresa', ['tableEmpresas'])
        },
        methods: {
                   ...mapActions('empresa', ['ActionFindEmpresa']),
                   ...mapActions('empresa', ['ActionSalvar']),
                   ...mapActions('empresa', ['ActionEditar']),
                   ...mapActions('empresa', ['ActionDeletar']),

                  iconVisible(perfil) {

                    if (perfil === 'administrador') {
                      return false
                    }

                    else {
                      return true
                    }
                  },
                  
          async searchEmpresa(e) {

                      if(e.target.value.length > 3){

                          await http.get('/empresa/consulta/'+ e.target.value).then(resp => {
                            console.log(resp)
                            this.listEmpresas= resp.data
                          })   
                      }
          },
          async searchEmpresaCnpj(e) {
              if (e.target.value.length > 3) {
                await http.get('/empresa/consultacnpj/' + e.target.value).then(resp => {
                  console.log(resp)
                  this.listEmpresas = resp.data
                })
              }
        },
          download: function () {

                        const data = XLSX.utils.json_to_sheet(this.listEmpresas)
                        const wb = XLSX.utils.book_new()
                        XLSX.utils.book_append_sheet(wb, data, 'data')
                        XLSX.writeFile(wb, 'table_empresa.xlsx')

                      },

          createItem() {
                        this.modalShow = true;
                        this.formdata = {
                          esocial:'false',
                          convenio: 'false',
                          empresafora: 'false'      
                        }
                        this.editedIndex = -1;
                    },

           editItem(item) {
                        this.modalShow = true;
                        this.editedIndex = this.fields.indexOf(item);
                        this.formdata = Object.assign({}, item);
                    },

            close(){
                        this.modalShow = false;
                        setTimeout(() => {
                              this.formdata = {}
                              this.editedIndex = -1;
                              this.ActionFindEmpresa()
                                        }, 300);
                   },
          habilitaConvenio(item) {
            return item ? false : true
          },
         async save() {

                      try {

                           delete this.formdata.ideEmpregador;

                            if(!this.formdata.id){//entra para salvar
                               // console.log(this.formdata) 
                                await this.ActionSalvar(this.formdata)
                                this.ActionFindEmpresa()
                                alert('Cadastrado com sucesso!')
                               this.close()
                            }

                            else{//entra para editar
                                  await this.ActionEditar(this.formdata)
                                  this.ActionFindEmpresa()
                              //   alert('Atualizado com sucesso!')
                                  this.close()
                                  this.listEmpresas = []
                            }

                            this.formdata = {} 

                      } catch (err) {
                          alert(err.data ? err.data.message : 'Não foi possível salvar a empresa')
                        }
                },  
              
              async deleteItem(data){

                            const index = this.listEmpresas.data.indexOf(data);  

                            if(confirm('Deseja excluir o item?') && this.listEmpresas.data.splice(index, 1) ){
                             //   await this.ActionDeletar(data.id)
                                this.editedItem = Object.assign({}, data); 
                            }
                      },

                  resetInfoModal() {
                                      this.infoModal.title = ''
                                      this.infoModal.content = ''
                  }

          }
  }
</script>
<style >
.fonteLinhas {
   font-size:14px;
   text-align: center
}
.fonteLinhasLeft {
   font-size:14px;

}
.select-selected {
  border-color: black;
  border: 6px solid;
}

.select-selected.select-arrow-active:after {
  border-color: black;
  top: 7px;
}
.select-items div,
.select-selected {
  color: black;
  padding: 8px 16px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.select-items {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.select-hide {
  display: none;
}

.pesquisa_select{

  position: relative;
  margin-top: 20px;

}


.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
@mixin flex-center($columns: false) {
  display: flex;
  align-items: center;
  justify-content: center;
  @if $columns {
    flex-direction: column;
  }
}

</style>